import { mapState } from 'vuex';
import { scrollInit } from '../utils/scroll';
const mapText = {
  viewMore: ['查看更多', 'More', '查看更多'],
  stow: ['收起', 'Stow', '收起'],
  company_intro: ['公司介绍', 'Company Profile', '公司介紹'],
  sense_of_worth: ['价值观', 'Sense Of Worth', '價值觀'],
  development_history: ['发展历程', 'Development History', '發展歷程'],
  management_layer: ['管理团队', 'Management Team', '管理團隊'],
  honors_qualifications: ['荣誉资质', 'Honors & Qualifications', '榮譽資質'],
  contact_us: ['联系我们', 'Contact Us', '聯繫我們'],
  market_surveillance: ['市场监督', 'Market Surveillance', '市場監督'],
  honor_product_qualification: ['产品资质', 'Product Qualification', '産品資質'],
  honor_production_qualification: ['生产资质', 'Production Qualification', '生産資質'],
  honor_business_qualification: ['经营资质', 'Business Qualification', '經營資質'],
  honor_laboratory_qualification: ['实验室资质', 'Laboratory Qualification', '實驗室資質'],
  honor_social_honor: ['社会荣誉', 'Social Honor', '社會榮譽'],
  development_history_title: ['诺辉大纪事', 'Development History', '諾輝大紀事'],
  sense_of_worth_title: ['改变生命的轨迹', 'Changing the Trajectory of Life', '改變生命的軌跡'],
  sense_of_worth_valuechain: ['企业价值观', 'Corporate values', '企業價值觀'],
  sense_of_worth_integrity: ['诚信-不负他人、不负自己', 'Sincerity— Do not live up to others 、Do not live up to yourself', '誠信-不負他人、不負自己'],
  sense_of_worth_passion: ['激情-自我驱动、追求卓越', 'Passion——Self-driven 、Striving for excellence', '激情-自我驅動、追求卓越'],
  sense_of_worth_execute: ['执行-一诺前行、使命必达', 'Execute——One promise to move forward 、Mission must be reached', '執行-一諾前行、使命必達'],
  sense_of_worth_create: ['创造-专注做难而正确的事', 'Create——Do the hard and right thing', '創造-專註做難而正確的事'],
  sense_of_worth_synergy: ['协同-有虚线无实线', 'Coordination——Teamwork', '協同-有虛線無實線']
};
const aLinks = [{
  id: 'company_intro',
  name: mapText.company_intro
}, {
  id: 'sense_of_worth',
  name: mapText.sense_of_worth_valuechain
}, {
  id: 'development_history',
  name: mapText.development_history_title
}, {
  id: 'management_layer',
  name: mapText.management_layer
}, {
  id: 'honors_qualifications',
  name: mapText.honors_qualifications
}, {
  id: 'contact_us',
  name: mapText.contact_us
}, {
  id: 'market_surveillance',
  name: mapText.market_surveillance
}];
const honorTitleList = [{
  honorType: 1,
  name: mapText.honor_product_qualification
}, {
  honorType: 2,
  name: mapText.honor_production_qualification
}, {
  honorType: 3,
  name: mapText.honor_business_qualification
}, {
  honorType: 5,
  name: mapText.honor_laboratory_qualification
}, {
  honorType: 4,
  name: mapText.honor_social_honor
}];
export default {
  name: "about_company",
  data() {
    return {
      showIntroduce: false,
      title: this.$api.api_title,
      about_company: {},
      development: [],
      developmentPic: {},
      honor: [],
      honorType: 1,
      managementList: [],
      activeName: '2022',
      showBtn: 1,
      mapText,
      isExpand: false,
      aLinks,
      currentIndex: 0,
      activeIndex: 0,
      honorTitleIndex: 0,
      yearEvent: {},
      honorTitleList,
      viewMore_s: false,
      peopleIndx: 0,
      canViewMore: false
    };
  },
  mounted() {
    if (this.$route.query.hash) scrollInit(this.$route.query.hash);
    // if(this.device == 'pc'){
    window.addEventListener('scroll', this.handleScroll, true);
    // }
  },
  watch: {
    $route(to, from) {
      // this.scrollInit()
      scrollInit(this.$route.query.hash);
    }
  },
  created() {
    this.getData();
    this.getHonorData();
  },
  computed: {
    ...mapState({
      device: state => state.device,
      language: state => state.language
    })
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    honorFun(honorType) {
      this.honorType = honorType;
      this.getHonorData();
    },
    getHonorData() {
      //荣誉资质
      this.$http.post(this.$api.honor_honorList, {
        honorType: this.honorType
      }).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.honor = res.data;
          }
        }
      });
    },
    getData() {
      //公司介绍
      this.$http.post(this.$api.introduce_introduceList).then(res => {
        if (res.code == 0) {
          if (res.data) {
            if (this.$store.state.language == 1) {
              this.about_company = res.data[0];
              this.$set(this.about_company, 'introduce', res.data[0].introduce);
            }
            if (this.$store.state.language == 2) {
              this.about_company = res.data[1];
              this.$set(this.about_company, 'introduce', res.data[1].introduce);
            }
            if (this.$store.state.language == 3) {
              this.about_company = res.data[2];
              this.$set(this.about_company, 'introduce', res.data[2].introduce);
            }
          }
        }
      });
      //发展历程
      this.$http.get(this.$api.development_developmentList, {
        params: {
          nhLanguage: this.$store.state.language
        }
      }).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.development = res.data;
            this.yearEvent = res.data[0];
          }
        }
      });
      //历程图
      this.$http.get(this.$api.developmentPic_developmentPicList, {
        params: {
          nhLanguage: this.$store.state.language
        }
      }).then(res => {
        if (res.code == 0) {
          if (res.data) {
            this.developmentPic = res.data[0];
          }
        }
      });
      //管理团队
      this.$http.get(this.$api.managementList).then(res => {
        if (res.code == 0) {
          this.managementList = res.data;
          this.managementList.forEach(item => {
            item.showIntroduce = false;
          });
        }
      });
    },
    scrollIntoView(id, index) {
      console.log('🚀 ~ id, index', id, index);
      this.currentIndex = index;
      const el = document.getElementById(id);
      if (el && id == 'management_layer') {
        el.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
          inline: 'start'
        });
        return;
      }
      if (el) el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    },
    checkYearEvent(event, index) {
      console.log('🚀 ~ event', event);
      this.activeIndex = index;
      this.yearEvent = event;
    },
    selectHonorType(item, index) {
      this.honorTitleIndex = index;
      this.honorType = item.honorType;
      this.getHonorData();
    },
    handleScroll() {
      // 价值观
      const sense_of_worth = this.$refs.sense_of_worth.getBoundingClientRect().top;
      if (this.device == 'pc') {
        this.changeClass(sense_of_worth, this.$refs.sense_of_worth_left, 'animate__fadeInTopLeft', 450);
      }
      this.changeClass(sense_of_worth, this.$refs.sense_of_worth_right, 'animate__fadeInBottomRight', 450);
      // 大纪事
      const development_history = this.$refs.development_history.getBoundingClientRect().top;
      this.changeClass(development_history, this.$refs.development_history_left, 'animate__fadeInLeft', 450);
      this.changeClass(development_history, this.$refs.development_history_center, 'animate__zoomIn', 300);
      this.changeClass(development_history, this.$refs.development_history_right, 'animate__zoomIn', 300);
      // 管理团队
      const management_layer = this.$refs.management_layer.getBoundingClientRect().top;
      this.changeClass(management_layer, this.$refs.management_layer_title, 'animate__fadeInLeft', 450);
      this.changeClass(management_layer, this.$refs.management_layer_content, 'animate__slideInUp', 350);
      // 荣誉资质
      const honors_qualifications = this.$refs.honors_qualifications.getBoundingClientRect().top;
      this.changeClass(honors_qualifications, this.$refs.honors_qualifications_title, 'animate__fadeInLeft', 500);
      this.changeClass(honors_qualifications, this.$refs.honors_qualifications_left, 'animate__fadeInLeft', 350);
      this.changeClass(honors_qualifications, this.$refs.honors_qualifications_right, 'animate__fadeInRight', 350);
      this.changeClass(honors_qualifications, this.$refs.honors_qualifications_circle, 'animate__zoomIn', 250);
      // 联系我们
      const contact_us = this.$refs.contact_us.getBoundingClientRect().top;
      this.changeClass(contact_us, this.$refs.contact_us_title, 'animate__fadeInLeft', 500);
      this.changeClass(contact_us, this.$refs.contact_us_content, 'animate__fadeInLeft', 350);
      this.changeClass(contact_us, this.$refs.contact_us_bottom, 'animate__fadeInRight', 350);
    },
    changeClass(distance, target, animate, top) {
      if (distance > top) return;
      target.classList.add(animate);
      target.classList.remove('display-none');
    },
    readMore(index, value) {
      this.$delete(this.managementList[index], 'showIntroduce');
      this.$set(this.managementList[index], 'showIntroduce', Boolean(value));
    },
    pcReadMore(item) {
      // this.peopleIndx = index
      // this.canViewMore = !this.canViewMore
      item.showIntroduce = !item.showIntroduce;
      this.$forceUpdate();
    }
  }
};