export function scrollInit(hash) {
  let jump = document.querySelector("#" + hash); //定义起跳点div
  let total = jump.offsetTop;
  let distance = document.documentElement.scrollTop || document.body.scrollTop;
  console.log(total, distance);
  // 平滑滚动，时长500ms，每10ms一跳，共50跳
  let step = total / 80;
  if (total > distance) {
    smoothDown();
  } else {
    let newTotal = distance - total;
    step = newTotal / 80;
    smoothUp();
  }
  function smoothDown() {
    if (distance < total) {
      distance += step;
      document.body.scrollTop = distance;
      document.documentElement.scrollTop = distance;
      setTimeout(smoothDown, 10);
    } else {
      document.body.scrollTop = total - 120;
      document.documentElement.scrollTop = total - 120;
    }
  }
  function smoothUp() {
    if (distance > total) {
      distance -= step;
      document.body.scrollTop = distance;
      document.documentElement.scrollTop = distance;
      setTimeout(smoothUp, 10);
    } else {
      document.body.scrollTop = total - 120;
      document.documentElement.scrollTop = total - 120;
    }
  }
}